


.user button {
    height: 42px;
    font-size: 12px;
}
.user td:not(:last-child) {
    width: 23%;
}
.user .active{
    border:none !important;
}
.user .page-item.active .page-link {
    color:var(--secondary)
}
.cell-clickable {
    cursor: pointer;
}
.user .sz-table {
    thead {
        tr {
        background-color: var(--table-header-bg-color) !important;
        }
        tr th{
            &:after {
                background: none !important;
            }
        }
    }
    tbody{
        tr td {
            &:after{
                background-color : var(--table-first-border-color)!important;
            }
        }
    }
   
}
.user{
    .info{
        color:var(--info-text);
        a {
            color: inherit;
        }
    }
    .danger{
        color:var(--danger-text);
        a {
            color: inherit;
        }
    }
}
.danger{
    .sz-underline::after {
        background: var(--danger-text) ;
    }
}
