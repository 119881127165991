@import ".././../mixins";
.add-icon .add-button:hover {
    font-family: "DINPro Medium";
    border: none;
}
.add-icon {
    position:fixed;
	bottom:40px;
	right:5%;
}
.add-icon .add-button{
    font-family: "DINPro Medium";
    border: none;
    border-radius: 50%;
    width:60px;
	height:60px;
}
.sz-icon-line.add-circle{
    line-height: 1.5;
}
.userContracts button{
    border-bottom: 2px solid white !important;
    border: transparent ;
    text-align: left;
    height: 45px;
    justify-content: flex-start !important;
    &.danger{
        @include color(var(--danger-text));
        border-bottom: none !important;
        box-shadow: none;
        @include background(transparent);
        a {
            color: inherit;
        }
    }
}