.add-user input, select  {
    border: none !important;
    box-sizing:content-box !important;
    border-bottom: 1px solid var(--input-border-bottom-color) !important;
    padding-bottom: 0px !important;
}
.add-user .error {
    border: none !important;
    box-sizing:content-box !important;
    border-bottom: 1px solid var(--error-text-color) !important;
    padding-bottom: 0px !important;
}
.add-user button {
    height: 40px;
    font-size: 12px;
}
.add-user #contract-controller button {
    width: 100%;
    background: transparent !important;
}
