




.sz-icon-line.search{
    font-size: 15px;
}
.sz-icon-line.remove{
    font-size: 15px;
}
