.contract-modal .modal-content {
    min-height: 50%;
}
.contract-modal .contracts-content button{
    
    text-align: left;
    width: 100%;
    height: 30px;
}
.contract-modal .modal-body {
    max-height: 500px;
    overflow: auto;
}
.contract-modal .selectedContract {
    border-left: 3px solid var(--selected-collpase-color) !important;
}